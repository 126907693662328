<template>
  <div class="container page">
    <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Reset Password</div>
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="password1">Password</label>
                  <input type="password" class="form-control" id="password" v-model="password1" placeholder="Enter password">
                </div>
                <div class="form-group">
                  <label for="password2">Repeat password</label>
                  <input type="password" class="form-control" id="password2" v-model="password2" placeholder="Repeat password">
                </div>
                <button type="submit" class="btn btn-block btn-primary">Change password</button>
              </form>
            </div>
          </div>
          <p>&nbsp;</p>
          <p>Remembered your password? <router-link to="/login">Log in</router-link></p>
          <p>Don’t have an account? <router-link to="/register">Register now!</router-link></p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "Reset Password",
    components: {  },
    data() {
      return {
        token: '',
        email: '',
        password1: '',
        password2: '',
      };
    },
    mounted() {
      this.token = this.$route.params.token;
      this.email = this.$route.query.email;
      console.log(this.token);
      console.log(this.email);
    },
    methods: {
      submitForm() {
        // Handle reset password logic here
        if ((this.password1 !== this.password2) || (this.password1 === '')) {
          this.$toast.error("Passwords do not match");
          return;
        } else {
          this.$http.get("/sanctum/csrf-cookie").then(() => {
            this.$http
              .post("/reset-password", {
                token: this.token,
                email: this.email,
                password: this.password1
              })
              .then(() => {
                this.$toast.success("Your password has been rest. You can now login with your new password.");
              })
          });
        }
      },
    },
}
</script>

<style lang="scss" scoped>
  
</style>
